import React from "react"
import Layout from "../components/layout";
import Title from "../components/title";
import { Helmet } from "react-helmet";

import Jiskra from "../images/jiskra.jpg"  
import Profil from "../images/profil.jpg"

const Kontakt = () => (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kontakt | Spark Performance</title> 
    </Helmet>
      <Layout>
      <Title title={'Kontakt'}  />
 
   
      <div class="relative bg-white py-16  ">
  <div class="grid lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
    <div class="relative py-8 sm:py-16 lg:py-0 order-2 lg:order-1">
      <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
        <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
        <svg class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
          <defs>
            <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
        </svg>
      </div>
      <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
        
        <div class="relative pt-80 pb-10 rounded-2xl shadow-xl overflow-hidden">
          <img class="absolute inset-0 h-full w-full object-cover" src={Jiskra} alt="" />
          <div class="absolute inset-0 bg-red-500" style={{mixBlendMode: "multiply"}}></div>
          <div class="absolute inset-0 bg-gradient-to-t from-red-600 via-red-600 opacity-70"></div>
          <div class="relative px-8">
             
            <blockquote class="mt-8">
              <div class="relative text-lg font-medium text-white md:flex-grow">
               
                <p class="relative">
                <svg className="w-1/2 text-white" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="currentColor"
                width="484.992px" height="55.896px" viewBox="0 0 484.992 55.896"  >
              <path d="M460.937,12.964C462.499,5.651,466.878,2,474.081,2c5.941,0,8.911,2.479,8.911,7.421c0,1.072-0.136,2.253-0.418,3.543
                l-1.498,7.031h-7.531l1.49-7.031c0.091-0.427,0.136-0.818,0.136-1.172c0-1.563-0.89-2.353-2.661-2.371h-0.055
                c-2.162,0-3.498,1.181-3.988,3.543l-1.018,4.742c-0.045,0.245-0.072,0.509-0.072,0.799c0,2.162,1.771,4.742,5.323,7.74
                c3.188,2.68,4.787,5.796,4.787,9.339c0,0.872-0.1,1.78-0.29,2.716l-1.418,6.604c-1.553,7.321-5.94,10.991-13.145,10.991
                c-5.94-0.019-8.911-2.507-8.911-7.458c0-1.063,0.137-2.243,0.427-3.533l1.49-7.031h7.53l-1.489,7.031
                c-0.092,0.427-0.137,0.826-0.137,1.198c0,1.554,0.899,2.335,2.689,2.335c2.18,0,3.524-1.172,4.015-3.533l1.281-5.96
                c0.072-0.309,0.108-0.608,0.108-0.908c0-2.307-1.771-4.951-5.296-7.931c-3.18-2.661-4.77-5.786-4.77-9.365
                c0-0.927,0.109-1.88,0.318-2.88L460.937,12.964z M433.448,12.964C435.011,5.651,439.389,2,446.593,2c5.94,0,8.911,2.479,8.911,7.421
                c0,1.072-0.136,2.253-0.418,3.543l-1.499,7.031h-7.53l1.489-7.031c0.092-0.427,0.137-0.818,0.137-1.172
                c0-1.563-0.89-2.353-2.662-2.371h-0.054c-2.162,0-3.498,1.181-3.988,3.543l-1.018,4.742c-0.045,0.245-0.072,0.509-0.072,0.799
                c0,2.162,1.771,4.742,5.323,7.74c3.188,2.68,4.787,5.796,4.787,9.339c0,0.872-0.1,1.78-0.29,2.716l-1.418,6.604
                c-1.553,7.321-5.94,10.991-13.145,10.991c-5.94-0.019-8.911-2.507-8.911-7.458c0-1.063,0.136-2.243,0.427-3.533l1.49-7.031h7.53
                l-1.489,7.031c-0.092,0.427-0.137,0.826-0.137,1.198c0,1.554,0.899,2.335,2.688,2.335c2.181,0,3.525-1.172,4.016-3.533l1.28-5.96
                c0.073-0.309,0.109-0.608,0.109-0.908c0-2.307-1.771-4.951-5.296-7.931c-3.18-2.661-4.77-5.786-4.77-9.365
                c0-0.927,0.109-1.88,0.318-2.88L433.448,12.964z M411.011,2.826h19.53l-1.571,7.422h-12l-3.198,14.961h10.329l-1.571,7.449H412.2
                l-3.188,14.962h12l-1.571,7.422h-19.531L411.011,2.826z M382.45,2.826h7.53l0.69,30.286l6.44-30.286h6.968l-11.092,52.216h-7.531
                l-0.645-29.333l-6.223,29.333h-7.24L382.45,2.826z M377.653,2.826l-1.571,7.422h-7.05l-9.529,44.794h-7.53l9.529-44.794h-7.059
                l1.571-7.422H377.653z M337.229,36.52l1.735-21.72l-7.485,21.72H337.229z M344.842,36.52l-1.49,18.522h-7.612l0.881-11.074h-7.694
                l-3.833,11.074h-7.586l6.359-18.441l12.509-33.774h10.328L344.842,36.52z M305.88,2.826h19.53l-1.571,7.422h-12l-3.197,14.961
                h10.328l-1.571,7.449h-10.329l-3.188,14.962h11.999l-1.571,7.422h-19.53L305.88,2.826z M284.051,10.248l-3.198,14.961h1.735
                c1.045,0,1.78-0.073,2.207-0.218c1.49-0.545,2.408-1.653,2.771-3.325l1.644-7.849c0.109-0.463,0.164-0.872,0.164-1.226
                c0-1.563-0.891-2.344-2.662-2.344H284.051z M278.092,2.826h10.964c5.424,0.019,8.14,2.435,8.14,7.24
                c0,1.117-0.154,2.371-0.454,3.751l-1.645,7.849c-0.999,4.596-3.624,7.621-7.885,9.075l3.307,24.3h-8.249l-1.916-22.965l-0.99,0.127
                l-4.842,22.838h-7.531L278.092,2.826z M262.758,44.904c-1.554,7.303-5.95,10.973-13.172,10.991c-5.923,0-8.885-2.479-8.885-7.431
                c0-1.063,0.137-2.253,0.427-3.561l6.786-31.94C249.477,5.651,253.855,2,261.059,2c5.941,0,8.912,2.479,8.912,7.421
                c0,1.072-0.137,2.253-0.418,3.543l-1.499,7.031h-7.531l1.49-7.031c0.091-0.427,0.136-0.818,0.136-1.172
                c0-1.563-0.89-2.353-2.661-2.371h-0.055c-2.162,0-3.497,1.181-3.987,3.543l-6.786,31.94c-0.091,0.444-0.137,0.845-0.137,1.198
                c0,1.554,0.891,2.335,2.662,2.335c2.198,0,3.552-1.172,4.042-3.533l2.607-12.246h-3.807l1.571-7.449h11.338L262.758,44.904z
                M210.679,8.895h19.531l-1.408,6.55h-12l-2.798,13.227h10.329l-1.408,6.595h-10.329l-2.798,13.199h12.009l-1.417,6.577h-19.531
                L210.679,8.895z M182.118,8.895h7.531l-4.206,19.776h6.577l4.206-19.776h7.531l-9.82,46.147h-7.531l4.206-19.776h-6.577
                l-4.206,19.776h-7.53L182.118,8.895z M177.321,8.895l-1.408,6.55h-7.049l-8.412,39.598h-7.531l8.412-39.598h-7.058l1.417-6.55
                H177.321z M125.561,28.935l4.388,26.107h-8.058l-3.089-23.21l-4.923,23.21h-7.531l11.101-52.216h7.531l-4.951,23.237l12.963-23.237
                h8.058L125.561,28.935z M95.619,10.248l-3.198,14.961h1.735c1.044,0,1.78-0.073,2.208-0.218c1.49-0.545,2.407-1.653,2.771-3.325
                l1.644-7.849c0.109-0.463,0.164-0.872,0.164-1.226c0-1.563-0.89-2.344-2.662-2.344H95.619z M89.66,2.826h10.964
                c5.423,0.019,8.139,2.435,8.139,7.24c0,1.117-0.154,2.371-0.454,3.751l-1.644,7.849c-1,4.596-3.625,7.621-7.885,9.075l3.307,24.3
                h-8.249l-1.917-22.965l-0.99,0.127L86.09,55.042h-7.53L89.66,2.826z M67.913,36.52l1.735-21.72l-7.485,21.72H67.913z M75.525,36.52
                l-1.49,18.522h-7.612l0.881-11.074H59.61l-3.833,11.074h-7.585l6.359-18.441L67.059,2.826h10.329L75.525,36.52z M43.349,10.248
                l-3.198,14.961h1.735c1.044,0,1.78-0.073,2.208-0.218c1.49-0.545,2.407-1.653,2.771-3.325l1.644-7.849
                c0.109-0.463,0.164-0.872,0.164-1.226c0-1.563-0.89-2.344-2.662-2.344H43.349z M37.39,2.826h10.964
                c5.423,0.019,8.14,2.435,8.14,7.24c0,1.117-0.155,2.371-0.454,3.751l-1.645,7.849c-1.154,5.396-4.306,8.875-9.447,10.438
                c-1.227,0.372-2.943,0.554-5.142,0.554H38.58l-4.76,22.384h-7.53L37.39,2.826z M7.213,12.964C8.775,5.651,13.154,2,20.357,2
                c5.941,0,8.912,2.479,8.912,7.421c0,1.072-0.136,2.253-0.418,3.543l-1.499,7.031h-7.531l1.49-7.031
                c0.091-0.427,0.136-0.818,0.136-1.172c0-1.563-0.89-2.353-2.662-2.371h-0.054c-2.162,0-3.498,1.181-3.988,3.543l-1.018,4.742
                c-0.045,0.245-0.073,0.509-0.073,0.799c0,2.162,1.771,4.742,5.323,7.74c3.188,2.68,4.788,5.796,4.788,9.339
                c0,0.872-0.1,1.78-0.291,2.716l-1.417,6.604c-1.553,7.321-5.941,10.991-13.145,10.991C2.97,55.877,0,53.389,0,48.438
                c0-1.063,0.136-2.243,0.427-3.533l1.49-7.031h7.53l-1.49,7.031c-0.091,0.427-0.136,0.826-0.136,1.198
                c0,1.554,0.899,2.335,2.689,2.335c2.18,0,3.524-1.172,4.015-3.533l1.281-5.96c0.073-0.309,0.109-0.608,0.109-0.908
                c0-2.307-1.771-4.951-5.296-7.931C7.44,27.444,5.85,24.319,5.85,20.74c0-0.927,0.109-1.88,0.318-2.88L7.213,12.964z"/>
              </svg>
                </p>
              </div>

               
            </blockquote>
          </div>
        </div>
      </div>
    </div>

    <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 order-1 lg:order-2">
      
      <div class="  sm:pt-16 lg:pt-20 ">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="  ">
            <div className="mx-auto text-center" >
              <img class="h-32 w-32 inline rounded-full ring-4 ring-white sm:h-48 sm:w-48" src={Profil} alt="" />
            </div>
            <div class="  ">
              <div class="sm:hidden md:block mt-6 min-w-0 ">
                <h1 class="text-4xl font-bold  truncate  text-center">
                  Jan Jiskra
                </h1>

            
              </div>
              <div class="mt-6 space-y-3  ">
                  <a href="mailto:spark@sparkperformance.cz">spark@sparkperformance.cz</a>
              </div>
            </div>
          </div>
          <div class="hidden sm:block md:hidden mt-6 min-w-0 ">
            <h1 class="text-4xl font-bold text-red-600 truncate">
              Jan Jiskra
            </h1>
          </div>
        </div> 
      </div>
 
    </div>
  </div> 
      
</div>
    </Layout>
  </>
)
 
export default Kontakt
